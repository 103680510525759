import React from "react";
import "./header.scss";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import WindowWidth from "../screen/screen.jsx";
import ScreenSizeContext from "../../../shared/context/screen.js";
import pqLogo from "../../../assets/images/pq_logo.png";
import fbLogo from "../../../assets/social_logos/fb_logo.png";
import linkedInLogo from "../../../assets/social_logos/linkedin_logo.png";
import youtubeLogo from "../../../assets/social_logos/youtube_logo.png";
import tinyPhone from "../../../assets/social_logos/tiny_phone.png";

export default function Header(props) {
  return (
    <WindowWidth>
      <ScreenSizeContext.Consumer>
        {screen => (
          <Navbar
            collapseOnSelect
            bg="primary"
            variant="dark"
            expand="lg"
            sticky="top"
          >
            {!screen.mobileScreen ? (
              <Navbar.Brand href="#/">
                <div style={{ fontSize: "40px" }}>
                  <img
                    src={pqLogo}
                    width="100"
                    height="100"
                    className="d-inline-block align-middle"
                    alt="PQ Controls logo"
                  />
                  <div className="d-inline">
                    <strong> PQ CONTROLS INC.</strong>
                  </div>
                </div>
              </Navbar.Brand>
            ) : (
              <Navbar.Brand href="#/">
                <img
                  src={pqLogo}
                  width="50"
                  height="50"
                  alt="PQ Controls logo"
                />
                <div className="d-inline">
                  <strong> PQ CONTROLS INC.</strong>
                </div>
              </Navbar.Brand>
            )}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end align-self-end flex-column"
            >
              <div className="justify-content-end align-self-end">
                {!screen.mobileScreen ? (
                  <>
                    <Nav className="justify-content-end align-self-end">
                      <Nav.Item
                        href="tel:860-583-6994"
                        style={{ color: "white" }}
                      >
                        <img
                          src={tinyPhone}
                          className="tiny-phone-icon"
                          alt="tinyphone"
                        />
                        <strong>1-860-583-6994</strong>
                      </Nav.Item>
                    </Nav>
                    <Nav className="justify-content-end align-self-end">
                      <a href="https://www.facebook.com/PQControls">
                        <img
                          src={fbLogo}
                          className="logo-icons"
                          alt="Facebook"
                        />
                      </a>
                      <a href="https://www.linkedin.com/company/pq-controls-inc.">
                        <img
                          src={linkedInLogo}
                          className="logo-icons"
                          alt="LinkedIn"
                        />
                      </a>
                      <a href="https://www.youtube.com/user/PQControlsIncBristol">
                        <img
                          src={youtubeLogo}
                          className="logo-icons"
                          alt="Youtube"
                        />
                      </a>
                    </Nav>
                  </>
                ) : (
                  ""
                )}
                <Nav>
                  <Nav.Link href="#/" active={props.props.activeHeaderItem === "HOME" ? true : false}>Home</Nav.Link>
                  <NavDropdown title="Products" id="products_dropdown">
                    <NavDropdown.Item href="#/products/joysticks" active={props.props.activeHeaderItem === "JOYSTICKS" ? true : false}>
                      Joysticks
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/products/levelsensors" active={props.props.activeHeaderItem === "LEVELSENSORS" ? true : false}> 
                      Level Sensors
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/products/footpedals" active={props.props.activeHeaderItem === "FOOTPEDALS" ? true : false}>
                      Foot Pedals
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/products/rotaryhalls" active={props.props.activeHeaderItem === "ROTARYHALLS" ? true : false}>
                      Rotary Hall
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/products/grips" active={props.props.activeHeaderItem === "GRIPS" ? true : false}>
                      Grips
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/product/ROLLER_ROCKER" active={props.props.activeHeaderItem === "ROLLERROCKERS" ? true : false}>
                      Roller Rockers
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/products/electronics" active={props.props.activeHeaderItem === "ELECTRONICS" ? true : false}>
                      Electronics
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Company" id="company_dropdown">
                    <NavDropdown.Item href="#/aboutus" active={props.props.activeHeaderItem === "ABOUTUS" ? true : false}>
                      About Us
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/quality" active={props.props.activeHeaderItem === "QUALITY" ? true : false}>
                      Quality
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/technology" active={props.props.activeHeaderItem === "TECHNOLOGY" ? true : false}>
                      Contactless Technology
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/distributors" active={props.props.activeHeaderItem === "DISTRIBUTORS" ? true : false}>
                      Distributors
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/prop65" active={props.props.activeHeaderItem === "PROP65" ? true : false}>
                      Prop 65 Compliance
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Support" id="support_dropdown">
                    <NavDropdown.Item href="#/rfq" active={props.props.activeHeaderItem === "RFQ" ? true : false}>
                      Request A Quote
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/valves" active={props.props.activeHeaderItem === "VALVEVERIFICATION" ? true : false}>
                      Valve Verification FAQ
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/order" active={props.props.activeHeaderItem === "HOWTOORDER" ? true : false}>
                      How To Order
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/warranty" active={props.props.activeHeaderItem === "WARRANTY" ? true : false}>
                      Warranty/Returns
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="assets/data/pq_catalog_20_25.pdf">Catalog</Nav.Link>
                  <Nav.Link href="#/contactus" active={props.props.activeHeaderItem === "CONTACTUS" ? true : false}>Contact Us</Nav.Link>
                </Nav>
              </div>
            </Navbar.Collapse>
          </Navbar>
        )}
      </ScreenSizeContext.Consumer>
    </WindowWidth>
  );
}
